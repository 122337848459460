export { IconArrowSwitch } from './arrow-switch'
export { IconBank } from './bank'
export { IconCasino } from './casino'
export { IconGift } from './gift'
export { IconHistory } from './history'
export { IconHome } from './home'
export { IconInformation } from './information'
export { IconLock } from './lock'
export { IconLotto } from './lotto'
export { IconMessage } from './message'
export { IconPerson } from './person'
export { IconPhone } from './phone'
export { IconSlot } from './slot'
export { IconSport } from './sport'
export { IconTicket } from './ticket'
export { IconUser } from './user'
export { IconUsers } from './users'
export { IconWalletIn } from './wallet-in'
export { IconWalletOut } from './wallet-out'
export { IconWheel } from './wheel'
