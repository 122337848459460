import { SVG } from '../svg'

type Props = {
  className?: string
}

export function IconWalletOut({ className }: Props) {
  // __RENDER
  return (
    <SVG className={className} width={24} height={24} viewBox='0 0 24 24'>
      <g clipPath='url(#clip0_63_95943)'>
        <path
          d='M4.42108 11.3688C2.67792 11.3688 1.26318 9.9541 1.26318 8.21094V17.6846C1.26318 20.4762 3.52424 22.7373 6.31582 22.7373H20.2106V11.3688H4.42108Z'
          strokeWidth={2}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12.2528 11.368C13.0865 9.59954 14.9054 6.94691 18.2149 7.84375V10.3069L22.737 5.7848L18.2149 1.2627V3.6627C13.7433 3.17006 10.636 6.32796 11.4065 11.368H12.2654H12.2528Z'
          strokeWidth={2}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13.2506 5.05273H4.42108C2.67792 5.05273 1.26318 6.46747 1.26318 8.21063C1.26318 9.95379 2.67792 11.3685 4.42108 11.3685H11.4063C10.9895 8.58958 11.7474 6.39168 13.2506 5.05273Z'
          strokeWidth={2}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12.2524 11.3681H15.1577V8.07129C13.7051 8.77866 12.783 10.2439 12.2524 11.3681Z'
          strokeWidth={2}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_63_95943'>
          <rect width={24} height={24} />
        </clipPath>
      </defs>
    </SVG>
  )
}
