import { SVG } from '../svg'

type Props = {
  className?: string
}

export function IconWalletIn({ className }: Props) {
  // __RENDER
  return (
    <SVG className={className} width={24} height={24} viewBox='0 0 24 24'>
      <g clipPath='url(#clip0_63_95936)'>
        <path
          d='M4.42108 11.3688C2.67792 11.3688 1.26318 9.9541 1.26318 8.21094V17.6846C1.26318 20.4762 3.52424 22.7373 6.31582 22.7373H20.2106V11.3688H4.42108Z'
          strokeWidth={2}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M22.7371 1.69248C16.9518 0.0503718 13.2002 3.29669 13.7434 8.14721H11.3687L14.5644 11.3683H17.0781L20.2739 8.14721H17.8487C16.5602 3.35984 22.7371 1.69248 22.7371 1.69248Z'
          strokeWidth={2}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13.7432 8.14747C13.6169 7.01063 13.7432 5.97484 14.059 5.05273H4.42108C2.67792 5.05273 1.26318 6.46747 1.26318 8.21063C1.26318 9.95379 2.67792 11.3685 4.42108 11.3685H14.5642L11.3684 8.14747H13.7432Z'
          strokeWidth={2}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>

      <defs>
        <clipPath id='clip0_63_95936'>
          <rect width={24} height={24} />
        </clipPath>
      </defs>
    </SVG>
  )
}
