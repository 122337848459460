import { SVG } from '../svg'

type Props = {
  className?: string
}

export function IconUser({ className }: Props) {
  // __RENDER
  return (
    <SVG className={className} width={21} height={18} viewBox='0 0 21 18'>
      <path
        d='M1.73779 16.5C3.0632 14.2892 5.96807 12.7762 10.5001 12.7762C15.0321 12.7762 17.9369 14.2892 19.2623 16.5M14.1001 5.1C14.1001 7.08823 12.4883 8.7 10.5001 8.7C8.51184 8.7 6.90007 7.08823 6.90007 5.1C6.90007 3.11177 8.51184 1.5 10.5001 1.5C12.4883 1.5 14.1001 3.11177 14.1001 5.1Z'
        strokeWidth={2}
        strokeLinecap='round'
      />
    </SVG>
  )
}
