import { SVG } from '../svg'

type Props = {
  className?: string
}

export function IconPhone({ className }: Props) {
  // __RENDER
  return (
    <SVG className={className} width={17} height={22} viewBox='0 0 17 22'>
      <path
        d='M5.4998 5.00004H11.4998M1.2998 3.80002V18.2C1.2998 19.5255 2.37432 20.6 3.6998 20.6H13.2998C14.6253 20.6 15.6998 19.5255 15.6998 18.2V3.80004C15.6998 2.47456 14.6253 1.40004 13.2998 1.40004L3.69981 1.40002C2.37432 1.40002 1.2998 2.47454 1.2998 3.80002ZM8.4998 15.8H8.58481V15.8769H8.4998V15.8Z'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SVG>
  )
}
