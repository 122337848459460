import { SVG } from '../svg'

type Props = {
  className?: string
}

export function IconTicket({ className }: Props) {
  // __RENDER
  return (
    <SVG className={className} width={24} height={24} viewBox='0 0 24 24'>
      <path
        d='M14.4002 15.1404V14.9466M14.3997 12.1375V11.9437M14.3997 9.14741V8.95358M5.01196 3.45996H12.0012C12.0409 4.87503 13.2005 6.00996 14.6252 6.00996C16.0499 6.00996 17.2094 4.87503 17.2491 3.45996H18.9884C21.0936 3.45996 22.8002 5.16655 22.8002 7.27173V16.7287C22.8002 18.8338 21.0936 20.5404 18.9884 20.5404H17.2491C17.2094 19.1254 16.0499 17.9904 14.6252 17.9904C13.2005 17.9904 12.0409 19.1254 12.0012 20.5404H5.01197C2.90679 20.5404 1.20021 18.8338 1.20021 16.7287L1.2002 7.27173C1.2002 5.16655 2.90678 3.45996 5.01196 3.45996Z'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SVG>
  )
}
