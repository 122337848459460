import { SVG } from '../svg'

type Props = {
  className?: string
}

export function IconMessage({ className }: Props) {
  // __RENDER
  return (
    <SVG className={className} width={18} height={18} viewBox='0 0 18 18'>
      <path
        d='M15.5774 11.5423C15.9707 10.6529 16.1891 9.66886 16.1891 8.63384C16.1891 4.65739 12.9655 1.43384 8.98906 1.43384C5.01261 1.43384 1.78906 4.65739 1.78906 8.63384C1.78906 12.6103 5.01261 15.8338 8.98906 15.8338C10.2693 15.8338 11.4715 15.4997 12.5132 14.9139L16.1904 15.8332L15.5774 11.5423Z'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SVG>
  )
}
