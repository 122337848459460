import { SVG } from '../svg'

type Props = {
  className?: string
}

export function IconInformation({ className }: Props) {
  // __RENDER
  return (
    <SVG className={className} width={18} height={18} viewBox='0 0 18 18'>
      <path
        d='M8.7 9.00005L8.7 12.6M8.7 6.33169V6.30005M1.5 9.00005C1.5 5.0236 4.72355 1.80005 8.7 1.80005C12.6765 1.80005 15.9 5.0236 15.9 9.00005C15.9 12.9765 12.6764 16.2 8.7 16.2C4.72355 16.2 1.5 12.9765 1.5 9.00005Z'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SVG>
  )
}
