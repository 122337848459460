import { SVG } from '../svg'

type Props = {
  className?: string
}

export function IconLock({ className }: Props) {
  // __RENDER
  return (
    <SVG className={className} width={17} height={22} viewBox='0 0 17 22'>
      <path
        d='M3.0998 7.80002V6.88574C3.0998 3.84683 5.50838 1.40002 8.49981 1.40002C11.4912 1.40002 13.8998 3.84683 13.8998 6.88574V7.80002M3.0998 7.80002C2.1098 7.80002 1.2998 8.62288 1.2998 9.6286V18.7714C1.2998 19.7772 2.1098 20.6 3.0998 20.6H13.8998C14.8898 20.6 15.6998 19.7772 15.6998 18.7714V9.6286C15.6998 8.62288 14.8898 7.80002 13.8998 7.80002M3.0998 7.80002H13.8998'
        strokeWidth={2}
        strokeLinecap='round'
      />
    </SVG>
  )
}
