import { SVG } from '../svg'

type Props = {
  className?: string
}

export function IconBank({ className }: Props) {
  // __RENDER
  return (
    <SVG className={className} width={23} height={22} viewBox='0 0 23 22'>
      <path
        d='M3.4999 14V11.3314M8.83323 14V11.3314M14.1666 14V11.3314M19.4999 14V11.3314M1.8999 17.4H21.0999V20.6H1.8999V17.4ZM1.8999 7.80002V5.66669L11.1054 1.40002L21.0999 5.66669V7.80002H1.8999Z'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SVG>
  )
}
