import { SVG } from '../svg'

type Props = {
  className?: string
}

export function IconHistory({ className }: Props) {
  // __RENDER
  return (
    <SVG className={className} width={24} height={24} viewBox='0 0 24 24'>
      <path
        d='M14.5169 14.4004L10.9169 13.2004V8.18265M20.5169 12.0004C20.5169 6.69846 16.2188 2.40039 10.9169 2.40039C5.61496 2.40039 1.31689 6.69846 1.31689 12.0004C1.31689 17.3023 5.61496 21.6004 10.9169 21.6004C14.4702 21.6004 17.5727 19.6698 19.2326 16.8004M17.8834 10.9469L20.2833 13.3469L22.6833 10.9469'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SVG>
  )
}
