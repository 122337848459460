import { SVG } from '../svg'

type Props = {
  className?: string
}

export function IconArrowSwitch({ className }: Props) {
  // __RENDER
  return (
    <SVG className={className} width={18} height={18} viewBox='0 0 18 18'>
      <path
        d='M3.80002 7.20005L1.10002 4.50005M1.10002 4.50005L3.80002 1.80005M1.10002 4.50005H15.5M12.8 10.8L15.5 13.5M15.5 13.5L12.8 16.2M15.5 13.5H1.10002'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SVG>
  )
}
