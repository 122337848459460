import { SVG } from '../svg'

type Props = {
  className?: string
}

export function IconHome({ className }: Props) {
  // __RENDER
  return (
    <SVG className={className} width={18} height={18} viewBox='0 0 18 18'>
      <path
        d='M6.6999 16.2V10.7223C6.6999 10.2181 7.12971 9.80936 7.6599 9.80936H10.5399C11.0701 9.80936 11.4999 10.2181 11.4999 10.7223V16.2M8.54356 1.96899L2.30356 6.18887C2.05032 6.36013 1.8999 6.63737 1.8999 6.93289V14.8306C1.8999 15.5869 2.54461 16.2 3.3399 16.2H14.8599C15.6552 16.2 16.2999 15.5869 16.2999 14.8306V6.93289C16.2999 6.63737 16.1495 6.36013 15.8962 6.18887L9.65624 1.96899C9.32316 1.74374 8.87664 1.74374 8.54356 1.96899Z'
        strokeWidth={2}
        strokeLinecap='round'
      />
    </SVG>
  )
}
