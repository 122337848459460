import { SVG } from '../svg'

type Props = {
  className?: string
}

export function IconUsers({ className }: Props) {
  // __RENDER
  return (
    <SVG className={className} width={17} height={12} viewBox='0 0 17 12'>
      <path
        d='M1.49999 10.6626C2.32397 9.28823 4.12988 8.34759 6.94733 8.34759C9.76478 8.34759 11.5707 9.28823 12.3947 10.6626M12.9159 7.30542C14.408 8.05144 15.154 8.79745 15.9 10.2895M11.3641 1.6032C12.0662 1.98086 12.5435 2.72244 12.5435 3.57548C12.5435 4.40387 12.0934 5.12715 11.4244 5.51412M9.18538 3.57545C9.18538 4.81149 8.18337 5.8135 6.94733 5.8135C5.71129 5.8135 4.70928 4.81149 4.70928 3.57545C4.70928 2.33941 5.71129 1.3374 6.94733 1.3374C8.18337 1.3374 9.18538 2.33941 9.18538 3.57545Z'
        strokeWidth={2}
        strokeLinecap='round'
      />
    </SVG>
  )
}
