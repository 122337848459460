import { SVG } from '../svg'

type Props = {
  className?: string
}

export function IconPerson({ className }: Props) {
  // __RENDER
  return (
    <SVG className={className} width={18} height={18} viewBox='0 0 18 18'>
      <path
        d='M4.03996 14.0337C4.38584 13.6463 6.00575 11.8637 6.48047 11.8637H11.4998C12.1877 11.8637 13.5919 13.3414 13.94 13.8623M16.19 8.63371C16.19 12.6102 12.9664 15.8337 8.98996 15.8337C5.01351 15.8337 1.78996 12.6102 1.78996 8.63371C1.78996 4.65727 5.01351 1.43372 8.98996 1.43372C12.9664 1.43372 16.19 4.65727 16.19 8.63371ZM11.5691 6.17968C11.5691 4.80605 10.4095 3.68372 8.99018 3.68372C7.5709 3.68372 6.41127 4.80605 6.41127 6.17968C6.41127 7.55332 7.5709 8.67565 8.99018 8.67565C10.4095 8.67565 11.5691 7.55332 11.5691 6.17968Z'
        strokeWidth={2}
      />
    </SVG>
  )
}
