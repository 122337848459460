import { SVG } from '../svg'

type Props = {
  className?: string
}

export function IconGift({ className }: Props) {
  // __RENDER
  return (
    <SVG className={className} width={18} height={18} viewBox='0 0 18 18'>
      <path
        d='M8.43101 16.2403V5.76753M7.21226 5.13927C7.34191 5.16836 7.47854 5.1327 7.57188 5.03935C7.66523 4.94601 7.70083 4.80934 7.67179 4.67973C7.55543 4.2044 7.12677 2.61272 6.68662 2.17257C6.13825 1.6242 5.24575 1.62191 4.7001 2.16757C4.15448 2.71319 4.15668 3.60569 4.70509 4.1541C5.15245 4.60145 6.73694 5.02291 7.21226 5.13927ZM8.54319 4.6797C8.5141 4.80935 8.54976 4.94597 8.6431 5.03932C8.73645 5.13266 8.87312 5.16827 9.00272 5.13923C9.47806 5.02286 11.0697 4.59421 11.5099 4.15406C12.0583 3.60569 12.0605 2.71319 11.5149 2.16753C10.9693 1.62191 10.0768 1.62412 9.52836 2.17253C9.08101 2.61988 8.65955 4.20437 8.54319 4.6797ZM1.62373 9.43299H14.9765C15.2657 9.43299 15.5001 9.19855 15.5001 8.90935V6.29117C15.5001 6.00197 15.2657 5.76753 14.9765 5.76753H1.62373C1.33454 5.76753 1.1001 6.00197 1.1001 6.29117V8.90935C1.1001 9.19855 1.33454 9.43299 1.62373 9.43299ZM14.191 9.43299V15.7166C14.191 16.0058 13.9566 16.2403 13.6674 16.2403H2.93282C2.64363 16.2403 2.40919 16.0058 2.40919 15.7166V9.43299H14.191Z'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SVG>
  )
}
