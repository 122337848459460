import { SVG } from '../svg'

type Props = {
  className?: string
}

export function IconWheel({ className }: Props) {
  // __RENDER
  return (
    <SVG className={className} width={25} height={24} viewBox='0 0 25 24'>
      <g clipPath='url(#clip0_156_162325)'>
        <path
          d='M12.1997 23C18.2748 23 23.1997 18.0751 23.1997 12C23.1997 5.92487 18.2748 1 12.1997 1C6.12457 1 1.19971 5.92487 1.19971 12C1.19971 18.0751 6.12457 23 12.1997 23Z'
          strokeWidth={2}
          strokeMiterlimit={10}
        />
        <path d='M12.1997 1V23' strokeWidth={2} strokeMiterlimit={10} />
        <path d='M1.19971 12H23.1997' strokeWidth={2} strokeMiterlimit={10} />
        <path d='M19.9799 4.21997L4.41992 19.78' strokeWidth={2} strokeMiterlimit={10} />
        <path d='M4.41992 4.21997L19.9799 19.78' strokeWidth={2} strokeMiterlimit={10} />
        <path
          d='M12.1099 14C13.2144 14 14.1099 13.1046 14.1099 12C14.1099 10.8954 13.2144 10 12.1099 10C11.0053 10 10.1099 10.8954 10.1099 12C10.1099 13.1046 11.0053 14 12.1099 14Z'
          strokeWidth={2}
          strokeMiterlimit={10}
        />
      </g>
      <defs>
        <clipPath id='clip0_156_162325'>
          <rect width={24} height={24} transform='translate(0.199707)' />
        </clipPath>
      </defs>
    </SVG>
  )
}
